.presence {
  text-align: center;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  padding-bottom: 30px;

  .ui.grid {
    margin-top: 0;
    margin-bottom: 0;
    height: 58px;
  }
}

.hoverable {
  font-size: 30px;
  color: #ccc;
  
  :hover {
    color: #fff;
  }

  .label {
    font-size: 16px;
    margin-left: -100%;
    margin-right: -100%;
    padding-top: 5px;
  }
}

a:hover {
  color: #fff !important;
}
