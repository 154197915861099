footer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding-bottom: 30px;  
}

a {
  color: #ccc;
}
