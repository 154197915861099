.home-banner {
  background:
    linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.40) 50%, rgba(0,0,0,0) 100%),
    url('../../lib/banners/home.jpg') center center/cover no-repeat;
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;

  .grid-container {
    width: 900px;
    margin-left: auto;
    margin-right: auto;

    .ui.grid {
      margin: 0;
  
      .row {
        &.top {
          padding-bottom: 0;
        }

        &.bottom {
          padding-top: 0;
        }

        .column {
          height: auto;
          line-height: normal;
        }
      }
    }
  }
}

.text {
  &.name {
    font-size: 100px;
    text-align: right;
    text-shadow: 3px 3px #222;
  }

  &.title {
    font-size: 48px;
    text-align: left;
    text-shadow: 2px 2px #222;
    position: absolute;
    bottom: 11px;
  }

  &.blurb {
    font-size: 28px;
    text-align: left;
    text-shadow: 2px 2px #222;
  }
}